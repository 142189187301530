<template>
    <div class="mian-box">
        <banner-carousel></banner-carousel>
        <div class="main-content-box">
            <div class="container">
                <div class="main-content-top">
                    <ul class="main-content-top-lf">
                        <li>
                            <router-link to="photograph">
                                <img :src="require('@/assets/images/main/photographic.jpg')" alt="">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="field">
                                <img :src="require('@/assets/images/main/space.jpg')" alt="">
                                <!-- 文字 -->
                               <!-- <div class="main-content-text">
                                    <div>空间共享</div>
                                    <div>Space Sharing</div>
                                </div> -->
                            </router-link>
                        </li>
                    </ul>
                    <ul class="main-content-top-rt">
                        <li>
                            <router-link to="lensman">
                                <img :src="require('@/assets/images/main/lensman.jpg')" alt="">
                                <!-- 文字 -->
                                <!-- <div class="main-content-text">
                                    <div>摄影师</div>
                                    <div>Photographer</div>
                                </div> -->
                            </router-link>
                        </li>
                        <li>
                            <router-link to="dresser">
                                <img :src="require('@/assets/images/main/dresser.jpg')" alt="">
                                <!-- 文字 -->
                                <!-- <div class="main-content-text">
                                    <div>化妆师</div>
                                    <div>Make-up Artist</div>
                                </div> -->
                            </router-link>
                        </li>
                        <li>
                            <router-link to="clothes">
                                <img :src="require('@/assets/images/main/clothes.jpg')" alt="">
                                <!-- 文字 -->
                                <!-- <div class="main-content-text">
                                    <div>服饰共享</div>
                                    <div>Clothing Sharing</div>
                                </div> -->
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="main-content-bottom">
                    <router-link to="video">
                        <img :src="require('@/assets/images/main/video.jpg')" alt="">
                        <!-- 文字 -->
                        <!-- <div class="main-content-text">
                            <div>影像课堂</div>
                            <div>Video</div>
                        </div> -->
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
	// 直接定义组件
    components: {
        bannerCarousel: () => import('@/components/bannerCarousel'),
    },
	// 注册方法
    methods: {
        ...mapActions([
            "getMainBanner",
        ]),
    },
	//  获取前后下的路由调用getMainBanner
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getMainBanner();
        });
    },
}
</script>

<style scoped>
/* 容器样式 */
.main-content-box{
    padding: 80px 0;
    background-color: #f5f5f5;
}
/* 上半部分 */
.main-content-top{
    display: flex;
    justify-content: space-between;
}
/* 公共样式 */
.main-content-top-lf,
.main-content-top-rt{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.main-content-top-lf>li+li,
.main-content-top-rt>li+li{
    margin-top: 10px;
}
.main-content-top-lf>li::before,
.main-content-top-rt>li::before{
    display: block;
    content: "";
    padding-top: 100%;
}
/* 左侧 */
.main-content-top-lf{
    width: 59%;
}
.main-content-top-lf>li{
    position: relative;
    background-color: #EBEEF5;
}
/* 右侧 */
.main-content-top-rt{
    width: 39%;
}
.main-content-top-rt>li{
    position: relative;
    background-color: #EBEEF5;
}
/* 下半部分 */
.main-content-bottom{
    position: relative;
    margin-top: 10px;
    background-color: #EBEEF5;
}
.main-content-bottom::before{
    display: block;
    content: "";
    padding-top: 30%;
}

/* 内容容器 */
.main-content-top>a,
.main-content-top-lf>li>a,
.main-content-top-rt>li>a,
.main-content-bottom>a{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* 图片 */
.main-content-bottom a>img,
.main-content-top a>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main-content-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #fff;
}
.main-content-text>div{
    font-size: 25px;
}
</style>